<template>
  <div class=" w-full flex flex-col items-center gap-10">
    <Wrapper class="max-w-xl">
      <div class="my-5 text-left">
        <h2 class="mb-2 text-2xl md:text-3xl text-blue-dark"><b>Registro</b></h2>
        <p class="text-sm text-gray-400">Ingrese todos los datos de la persona que tiene la inconformidad</p>
      </div>
      <SignUpForm :onSubmit="signUp" />
    </Wrapper>
    <div class="w-11/12 max-w-xl mt-5 md:mt-0">
        <AccordionList :items="accordionList" />
    </div>
  </div>
</template>

<script>
import SignUpForm from '@/components/Login/SignUpForm'
export default {
  components: {
    SignUpForm
  },
  data(){
    return {
      accordionList: [
      {title: 'Durante el registro tenga en cuenta', body: 'Su diligenciamiento le permite acceder a la plataforma para presentar quejas, conciliaciones y demandas. Los datos suministrados deben ser los del consumidor financiero que tiene la inconformidad.'}
      ]
    }
  },
  async mounted() {
    await this.$store.dispatch("session/clearSessionVerify");
  },
  methods: {
    switchLocale() {
      if(this.$i18n.locale === 'en'){
        this.$i18n.locale = 'es'
      }
      else {
        this.$i18n.locale = 'en'
      }
    },
    signUp(){
      window.alert('Sign Up');
      
    }
  },
  computed: {
    displayLocale(){
      let locale = 'EN';
      if(this.$i18n.locale === 'es'){
        locale = 'ES'
      } else {
        locale = 'EN' 
      }
      return locale;
    }
  }
}
</script>