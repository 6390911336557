<template>
  <div class="w-full">
    <div class="w-full">
      <BaseInput
        ref="names"
        type="text"
        label="Nombres"
        v-model="form.first_name"
        placeholder="Julio Roman"
        required="true"
        :maxlength="25"
      />
      <BaseInput
        ref="last_name"
        type="text"
        label="Apellidos"
        v-model="form.last_name"
        placeholder="Henriquez"
        required="true"
        :maxlength="24"
      />
      <BaseSelect
        ref="document_type"
        id="documentType"
        label="Tipo de identificación"
        :selectOptions="customIdentificationType"
        v-model="form.identification_type"
        required="true"
      />
      <BaseInput
        ref="identification_number"
        type="text"
        :identification="form.identification_type"
        label="Número de identificación"
        placeholder="Ej:123456789"
        v-model="form.identification_number"
        required="true"
      />
      <BaseInput
        ref="identification_number_confirmation"
        type="text"
        :identification="form.identification_type"
        label="Confirmar número de identificación"
        placeholder="Ej:123456789"
        v-model="form.identification_number_confirmation"
        required="true"
        @click.native.right.prevent
        @copy.native.prevent
        @paste.native.prevent
      />
      <BaseDate
        ref="birthdate"
        id="birthdate"
        label="Fecha de nacimiento"
        placeholder=""
        v-model="form.birth_date"
        required="true"
        :disabledDate="disabledDate"
      />
   
      <BaseSelect
        ref="phone_country_abrev"
        name="phone_country_abrev"
        id="phone_country_abrev"
        label="País del teléfono"
        :selectOptions="optionsIndicatived"
        v-model="form.phone_country_abrev"
        required="true"
      />

      <BaseInput
        v-if="form.phone_country_abrev != ''"
        ref="cellphone"
        name="phone"
        label="Número de teléfono (fijo o celular)"
        subtitle="(Numero fijo, incluye el indicativo nacional. Ej: 6011234567. Para celulares, ingresa únicamente el número. Ej: 3211234567"
        placeholder="Ej: 3211234567"
        v-model="form.phone"
        required="true"
        type="text"
        phoneNumber
        maxlength="15"
      />
      
      <BaseInput
        ref="email"
        type="text"
        label="Correo electrónico"
        v-model="form.email"
        placeholder="ejemplo@ejemplo.com"
        required="true"
        autocomplete="username"
        :maxlength="50"
      />
      <BaseInput
        ref="email_confirmation"
        type="text"
        label="Confirmar correo electrónico"
        v-model="form.emailConfirmation"
        placeholder="ejemplo@ejemplo.com"
        required="true"
        autocomplete="username"
        :maxlength="50"
        @click.native.right.prevent
        @copy.native.prevent
        @paste.native.prevent
      />
      <BasePassword
        ref="password"
        id="password"
        label="Contraseña"
        v-model="form.password"
        placeholder="**********"
        required="true"
        :legend="true"
        autocomplete="new-password"
      />
      <BasePassword
        ref="password_confirmation"
        id="verifyPassword"
        label="Confirmar contraseña"
        v-model="form.passwordConfirmation"
        placeholder="**********"
        required="true"
        :legend="true"
        autocomplete="new-password"
        @click.native.right.prevent
        @copy.native.prevent
        @paste.native.prevent
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["msgfeedback"],
  data() {
    return {
      visible: false,
      form: {
        first_name: "",
        last_name: "",
        identification_type: "",
        identification_number: "",
        identification_number_confirmation: "",
        birth_date: "",
        phone_country_abrev: "",
        phone_indicative: "",
        phone_type_id: "",
        phone: "",
        is_confirmed_phone: false,
        email: "",
        emailConfirmation: "",
        password: "",
        passwordConfirmation: "",
        id_company: 2,
      },
    
      feedback: this.msgfeedback,
      inputError: "",
      disabledDate: (current) => {
        // Can not select days after today
        return current && current > moment().endOf("day");
      },
    };
  },
  computed: {
    formOptions: function () {
      return this.$store.state.form;
    },
    customIdentificationType: function () {
      return this.$store.state.form?.optionsIdentificationType?.filter(
        (identification) => identification.id != 3
      );
    },
    optionsIndicatived: function () {
      return this.$store.state.form?.optionsCountriesAbrevs;
    }
  },
  watch: {
    form: {
      handler: function (newVal, oldVal) {
        this.$emit("setForm", {
          ...newVal,
          email: newVal.email.toLowerCase(),
          emailConfirmation: newVal.emailConfirmation.toLowerCase(),
        });
      },
      deep: true,
    },
  },
};
</script>